.tab-view{
     padding: 0 10px 0 10px;
     :focus{
        text-decoration: underline;
        font-weight: bolder;
 
    }
    .option{
        padding: 20px;
    }
    span {
        float: right;
        padding-right: 30px;
        cursor: pointer;
      }
 }