body {
  background-color: rgb(54, 54, 54);
  color: white;
  font-family: "Barlow Condensed", sans-serif;
  font-size: medium;
}
a {
  text-decoration: none;
  color: white;
}
 