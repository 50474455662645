.header {
  background-color: black;
  width: auto;
  height: 70px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-right: 30px;
  padding-left: 30px;
  .profile {
    height: 100%;
    width: 70px;
    padding: 25px;
  }
  .logo {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: xx-large;
  }
  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}
