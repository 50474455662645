.item-preview {
  background-color: rgb(39, 39, 39);
  display: flex;
  flex-direction: row;
  width: auto;
  height: 150px;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: smaller;
  font-weight: bold;
  .img {
    width: 20%;
     border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .details {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    div{
      margin-bottom: 10px;
    }
    .label {
      color: rgb(71, 71, 71);
    }
  }
  .badg {
    text-align: center;
    width: 30%;
     span {
      margin: 7px;
      padding: 10px 20px;
      background-color:black;
      border-radius: 30px;
      font-weight: bold;

     }
  }
}
