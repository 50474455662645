.filter {
  margin-right: 50px;
  width: auto;
  height: 200px;
  padding: 20px;
  background-color: black;
  border-radius: 20px;
  .select-container {
    background-color: black;
    padding-bottom: 5px;
    .select {
      padding: 10px;
      width: 90%;
      border-radius: 10px;
      background-color: black;
      color: white;
    }
  }
}
.btn {
  padding: 5px 10px;
  margin: 10px ;
  background-color: black;
  color: white;
  border: 1px solid white;
  &:active{
    background-color: white;
    color: black;
 
  }
}
